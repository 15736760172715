<template>
  <div class="a-period d-inline-block pa-0" :class="dense ? 'dense' : ''">
    <v-tooltip v-if="periodData" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          x-small
          :color="bw ? 'grey' : periodData.color"
          :class="dense ? 'px-2' : ''"
          dark
          outlined
          active
          class="mx-0 text-uppercase"
          background-color="white"
          v-bind="attrs"
          v-on="on"
          style="background-color: white !important;"
        >
          {{ getLiteral(period) }}</v-chip
        >
      </template>
      <span>{{ getTooltip(period) }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "a-Period",
  components: {},
  props: {
    period: {
      type: String,
      required: true,
      default: ""
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    bw: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: vm => ({}),
  methods: {
    getLiteral(period) {
      if (this.current_hotel) {
        if (this.pickups[period]) {
          return this.pickups[period];
        } else {
          return period.replace("_", " ");
        }
      }
    },
    getTooltip(period) {
      if (this.current_hotel) {
        if (this.pickups_full[period]) {
          return this.pickups_full[period];
        } else {
          return this.$t(period.replace("_", " "));
        }
      }
    },
    getRealPeriod(period) {
      if (period) {
        let p = period.split(" ");
        return p[p.length - 1];
      } else {
        return "";
      }
    }
  },
  mounted() {},
  computed: {
    periodData() {
      return this.hotelsdot.series_ui[this.getRealPeriod(this.period)];
    },
    pickups() {
      if (!this.current_hotel) {
        return {};
      }
      return {
        pickup_1: "PU " + this.current_hotel.settings.pickup_spots["pickup_1"],
        pickup_2: "PU " + this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_3: "PU " + this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_4: "PU " + this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_5: "PU " + this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_6: "PU " + this.current_hotel.settings.pickup_spots["pickup_6"],
        pickup_stly_1:
          "PU STLY " + this.current_hotel.settings.pickup_spots["pickup_1"],
        pickup_stly_2:
          "PU STLY " + this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_stly_3:
          "PU STLY " + this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_stly_4:
          "PU STLY " + this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_stly_5:
          "PU STLY " + this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_stly_6:
          "PU STLY " + this.current_hotel.settings.pickup_spots["pickup_6"]
      };
    },
    pickups_full() {
      if (!this.current_hotel) {
        return {};
      }
      return {
        pickup_1:
          "Pickup " + this.current_hotel.settings.pickup_spots["pickup_1"],
        pickup_2:
          "Pickup " + this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_3:
          "Pickup " + this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_4:
          "Pickup " + this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_5:
          "Pickup " + this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_6:
          "Pickup " + this.current_hotel.settings.pickup_spots["pickup_6"],
        pickup_stly_1:
          "Pickup STLY " + this.current_hotel.settings.pickup_spots["pickup_1"],
        pickup_stly_2:
          "Pickup STLY " + this.current_hotel.settings.pickup_spots["pickup_2"],
        pickup_stly_3:
          "Pickup STLY " + this.current_hotel.settings.pickup_spots["pickup_3"],
        pickup_stly_4:
          "Pickup STLY " + this.current_hotel.settings.pickup_spots["pickup_4"],
        pickup_stly_5:
          "Pickup STLY " + this.current_hotel.settings.pickup_spots["pickup_5"],
        pickup_stly_6:
          "Pickup STLY " + this.current_hotel.settings.pickup_spots["pickup_6"]
      };
    },
    ...mapState({
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  }
};
</script>
<style lang="scss">
.a-period {
  line-height: 16px;
  letter-spacing: 0.5px !important;

  &.dense {
    line-height: 13px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
</style>
