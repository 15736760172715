var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"a-rms-revenue-summary-table",attrs:{"headers":_vm.headers,"items":_vm.rows,"hide-default-footer":"","disable-pagination":"","item-class":_vm.getDatatableRowClass},scopedSlots:_vm._u([{key:"header.otb",fn:function(ref){
var header = ref.header;
return [_c('a-period',{attrs:{"period":header.value}})]}},{key:"header.stly",fn:function(ref){
var header = ref.header;
return [_c('a-period',{attrs:{"period":header.value}})]}},{key:"header.ly",fn:function(ref){
var header = ref.header;
return [_c('a-period',{attrs:{"period":header.value}})]}},{key:"header.bud",fn:function(ref){
var header = ref.header;
return [_c('a-period',{attrs:{"period":header.value}})]}},{key:"item.kpi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.kpi))+" ")]}},{key:"item.otb",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":item.kpi,"value":item.otb,"small":""}})]}},{key:"item.stly",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":item.kpi,"value":item.stly,"small":""}})]}},{key:"item.ly",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":item.kpi,"value":item.ly,"small":""}})]}},{key:"item.bud",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":item.kpi,"value":item.bud,"small":""}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }