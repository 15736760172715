<template>
  <div class="d-inline-block pa-0">
    <div
      class="d-flex grey lighten-5 rounded-pill pa-0"
      :class="vertical ? 'flex-column' : 'flex-row'"
      style="line-height: 16px"
    >
      <a-period
        :period="periods[0]"
        :dense="dense"
        class="pa-0"
        :bw="bw"
      ></a-period>
      <template v-if="periods.length > 1">
        <div
          v-for="period in periods.slice(1)"
          :key="period"
          :class="vertical ? 'text-center' : ''"
        >
          <small
            class="mx-1 grey--text body"
            :class="vertical ? 'd-block' : 'd-inline'"
            >vs</small
          >
          <a-period :period="period" :dense="dense" :bw="bw"></a-period>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aPeriod from "@/components/rms_ui/aPeriod";
export default {
  name: "a-period-compare",
  components: {
    aPeriod
  },
  props: {
    periods: {
      type: Array,
      default: () => []
    },
    vertical: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    bw: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: vm => ({}),
  methods: {},
  mounted() {},
  computed: {
    periodsData() {
      return this.hotelsdot.series_ui[this.period];
    },
    ...mapState({
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
