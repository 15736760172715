<template>
  <v-data-table
    :headers="headers"
    :items="revenue_channels_table"
    hide-default-footer
    disable-pagination
    :item-class="getDatatableRowClass"
    class="a-rms-revenue-channels-table"
  >
    <template v-slot:[`item.dimension`]="{ item }">
      <v-avatar
        size="10"
        class="mr-2"
        :color="row_color(item.dimension)"
      ></v-avatar>
      <b>{{ row_title(item.dimension) }}</b>
    </template>
    <template v-slot:[`item.room_revenue`]="{ item }">
      <a-kpi
        kpi="room_revenue"
        :value="item.room_revenue"
        :vs_values="[item.room_revenue_vs]"
        :vs_periods="['stly']"
        x_small
      ></a-kpi>
    </template>
    <template v-slot:[`item.room_revenue_avg`]="{ item }">
      <a-kpi
        kpi="room_revenue_avg"
        :value="item.room_revenue_avg"
        :vs_values="[item.room_revenue_avg_vs]"
        :vs_periods="['stly']"
        x_small
      ></a-kpi>
    </template>
    <template v-slot:[`item.rn`]="{ item }">
      <a-kpi
        kpi="rn"
        :value="item.rn"
        :vs_values="[item.rn_vs]"
        :vs_periods="['stly']"
        x_small
      ></a-kpi>
    </template>
    <template v-slot:[`item.rn_avg`]="{ item }">
      <a-kpi
        kpi="rn_avg"
        :value="item.rn_avg"
        :vs_values="[item.rn_avg_vs]"
        :vs_periods="['stly']"
        x_small
      ></a-kpi>
    </template>
    <template v-slot:[`item.adr`]="{ item }">
      <a-kpi
        kpi="adr"
        :value="item.adr"
        :vs_values="[item.adr_vs]"
        :vs_periods="['stly']"
        x_small
      ></a-kpi>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
import * as Utils from "@/utils.js";
import aKpi from "@/components/rms_ui/aKpi.vue";

export default {
  name: "a-rms-revenue-channels-table",
  components: {
    aKpi
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "dimension",
          align: "start",
          sortable: false
        },
        {
          text: this.$t("room_revenue"),
          value: "room_revenue",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("room_revenue_avg"),
          value: "room_revenue_avg",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("rn"),
          value: "rn",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("rn_avg"),
          value: "rn_avg",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("adr"),
          value: "adr",
          align: "center",
          sortable: false
        }
      ];
    },
    ...mapState({
      revenue_channels_table: state => state.rms.revenue_channels_table,
      segments: state => state.segmentschannels.segments
    })
  },
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    row_color(channel) {
      return Utils.getDimensionColor(this.segments, channel);
    },
    row_title(channel) {
      return Utils.getDimensionName(this.segments, channel);
    }
  }
};
</script>
