<template>
  <v-data-table
    :headers="headers"
    :items="rows"
    hide-default-footer
    disable-pagination
    :item-class="getDatatableRowClass"
    class="a-rms-revenue-summary-table"
  >
    <template v-slot:header.otb="{ header }">
      <a-period :period="header.value"></a-period>
    </template>
    <template v-slot:header.stly="{ header }">
      <a-period :period="header.value"></a-period>
    </template>
    <template v-slot:header.ly="{ header }">
      <a-period :period="header.value"></a-period>
    </template>
    <template v-slot:header.bud="{ header }">
      <a-period :period="header.value"></a-period>
    </template>
    <template v-slot:[`item.kpi`]="{ item }">
      {{ $t(item.kpi) }}
    </template>
    <template v-slot:[`item.otb`]="{ item }">
      <a-kpi :kpi="item.kpi" :value="item.otb" small></a-kpi>
    </template>
    <template v-slot:[`item.stly`]="{ item }">
      <a-kpi :kpi="item.kpi" :value="item.stly" small></a-kpi>
    </template>
    <template v-slot:[`item.ly`]="{ item }">
      <a-kpi :kpi="item.kpi" :value="item.ly" small></a-kpi>
    </template>
    <template v-slot:[`item.bud`]="{ item }">
      <a-kpi :kpi="item.kpi" :value="item.bud" small></a-kpi>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aPeriod from "@/components/rms_ui/aPeriod.vue";

export default {
  name: "a-rms-revenue-summary-table",
  components: {
    aKpi,
    aPeriod
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "kpi",
          align: "start",
          sortable: false
        },
        {
          text: this.$t("otb"),
          value: "otb",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("stly"),
          value: "stly",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("ly"),
          value: "ly",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("bud"),
          value: "bud",
          align: "center",
          sortable: false
        }
      ];
    },
    ...mapGetters({
      rows: "rms_revenue_summary_data_table"
    })
  },
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    }
  }
};
</script>
<style lang="scss">
.a-rms-revenue-summary-table {
  .a-datatable-row {
    td {
      &:first-child {
        text-transform: capitalize;
      }
    }
  }
}
</style>
