<template>
  <div class="a-rms-revenue-kpis">
    <v-row>
      <v-col cols="6">
        <a-card-kpi-progress
          :value="revenue_range_kpis.otb.occupancy"
          :vs_values="[revenue_range_kpis[revenue_vs].occupancy]"
          :periods="['otb', revenue_vs]"
          kpi="occupancy"
          label="occupancy"
          icon="mdi-bed"
          :progress_value="revenue_range_kpis.otb.rn"
          :progress_total="revenue_range_kpis.otb.available"
        ></a-card-kpi-progress>
      </v-col>
      <v-col cols="6">
        <a-card-kpi-icon
          :value="revenue_range_kpis.otb.intermediation"
          :vs_values="[revenue_range_kpis[revenue_vs].intermediation]"
          :periods="['otb', revenue_vs]"
          kpi="intermediation"
          label="intermediation"
          icon="mdi-handshake"
          icon_circle
        ></a-card-kpi-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <a-card-kpi-icon
          :value="revenue_range_kpis.otb.adr"
          :vs_values="[revenue_range_kpis[revenue_vs].adr]"
          :periods="['otb', revenue_vs]"
          kpi="adr"
          label="adr"
          icon="mdi-tag-outline"
          icon_circle
        ></a-card-kpi-icon>
      </v-col>
      <v-col cols="6">
        <a-card-kpi-icon
          :value="revenue_range_kpis.otb.revpar"
          :vs_values="[revenue_range_kpis[revenue_vs].revpar]"
          :periods="['otb', revenue_vs]"
          kpi="revpar"
          label="revpar"
          icon="mdi-ticket"
          icon_circle
        ></a-card-kpi-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <a-rms-revenue-reservation-percentage></a-rms-revenue-reservation-percentage>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aCardKpiProgress from "@/components/rms_ui/aCardKpiProgress.vue";
import aCardKpiIcon from "@/components/rms_ui/aCardKpiIcon.vue";
import aRmsRevenueReservationPercentage from "@/components/rms/aRmsRevenueReservationPercentage.vue";

export default {
  name: "a-rms-revenue-kpis",
  components: {
    aCardKpiProgress,
    aCardKpiIcon,
    aRmsRevenueReservationPercentage
  },
  computed: {
    ...mapState({
      revenue_range_kpis: state => state.rms.revenue_range_kpis,
      revenue_vs: state => state.rms.revenue_vs
    })
  }
};
</script>

<style lang="scss" scoped>
.row {
  &:last-child {
    .col {
      padding-bottom: 0 !important;
    }
  }
  .col {
    padding: 6px !important;
    &:last-child {
      padding-right: 12px !important;
    }
  }
}
</style>
