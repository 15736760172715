var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"a-rms-revenue-channels-table",attrs:{"headers":_vm.headers,"items":_vm.revenue_channels_table,"hide-default-footer":"","disable-pagination":"","item-class":_vm.getDatatableRowClass},scopedSlots:_vm._u([{key:"item.dimension",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"10","color":_vm.row_color(item.dimension)}}),_c('b',[_vm._v(_vm._s(_vm.row_title(item.dimension)))])]}},{key:"item.room_revenue",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":"room_revenue","value":item.room_revenue,"vs_values":[item.room_revenue_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.room_revenue_avg",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":"room_revenue_avg","value":item.room_revenue_avg,"vs_values":[item.room_revenue_avg_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.rn",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":"rn","value":item.rn,"vs_values":[item.rn_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.rn_avg",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":"rn_avg","value":item.rn_avg,"vs_values":[item.rn_avg_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.adr",fn:function(ref){
var item = ref.item;
return [_c('a-kpi',{attrs:{"kpi":"adr","value":item.adr,"vs_values":[item.adr_vs],"vs_periods":['stly'],"x_small":""}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }