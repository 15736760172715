<template>
  <div id="rms-revenue-app">
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-row v-if="!is_previous_date">
          <v-col cols="6">
            <a-card :title="$t('summary_situation')">
              <a-rms-revenue-summary-table></a-rms-revenue-summary-table>
            </a-card>
          </v-col>
          <v-col cols="6">
            <a-card :title="$t('bookings')">
              <template v-slot:subtitle>
                <a-period-compare
                  :periods="['otb', revenue_vs]"
                  class="ml-5"
                ></a-period-compare>
              </template>
              <a-rms-revenue-bookings></a-rms-revenue-bookings>
            </a-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="!is_previous_date ? 12 : 7">
            <a-card class="mb-5" :title="$t('production_by_service_type')">
              <template v-slot:options>
                <a-period-compare
                  :periods="['otb', revenue_vs]"
                ></a-period-compare>
              </template>
              <a-rms-revenue-servicetype></a-rms-revenue-servicetype>
            </a-card>
          </v-col>
          <v-col v-if="is_previous_date" cols="5" class="kpis-col">
            <a-rms-revenue-kpis></a-rms-revenue-kpis>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <a-card
              class="mb-5"
              :title="$t('accommodation_production_by_channel')"
            >
              <template v-slot:options>
                <a-period-compare
                  :periods="['otb', revenue_vs]"
                ></a-period-compare>
              </template>
              <v-row>
                <v-col cols="12">
                  <a-rms-revenue-channels></a-rms-revenue-channels>
                </v-col>
              </v-row>
            </a-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:loading>
        <v-row class="pt-5">
          <v-col cols="7">
            <v-card height="318px" class="pr-3">
              <v-row>
                <v-col cols="4" class="pa-0">
                  <v-row v-for="i in 3" :key="i" class="ma-0">
                    <v-col cols="12" class="pa-0 pl-3">
                      <v-skeleton-loader
                        type="list-item-three-line"
                        class="elevation-0"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="8" class="mt-2">
                  <v-skeleton-loader type="image"> </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="5" class="mt-n3">
            <v-row>
              <v-col cols="6" v-for="i in 2" :key="i">
                <v-card height="90px">
                  <v-skeleton-loader
                    type="list-item-three-line"
                    class="elevation-0"
                  >
                  </v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-for="i in 2" :key="i">
                <v-card height="90px">
                  <v-skeleton-loader
                    type="list-item-three-line"
                    class="elevation-0"
                  >
                  </v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card height="90px">
                  <v-skeleton-loader
                    type="list-item-three-line"
                    class="elevation-0"
                  >
                  </v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <a-card>
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
          </v-row>
        </a-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aCard from "@/components/aCard.vue";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aRmsRevenueSummaryTable from "@/components/rms/aRmsRevenueSummaryTable.vue";
import aRmsRevenueChannels from "@/components/rms/aRmsRevenueChannels.vue";
import aRmsRevenueServicetype from "@/components/rms/aRmsRevenueServicetype.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aRmsRevenueKpis from "@/components/rms/aRmsRevenueKpis.vue";
import aRmsRevenueBookings from "@/components/rms/aRmsRevenueBookings.vue";

export default {
  name: "rms-revenue-app",
  components: {
    aCard,
    aPeriodCompare,
    aBaseComponent,
    aRmsRevenueSummaryTable,
    aRmsRevenueChannels,
    aRmsRevenueServicetype,
    aRmsRevenueKpis,
    aRmsRevenueBookings
  },
  props: {
    event_period: {
      type: Array,
      default: () => []
    },
    is_previous_date: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    subscribed_modules: [
      "rms_revenue_summary_table",
      "rms_revenue_channels_table",
      "rms_revenue_servicetype_data",
      "rms_revenue_range_kpis",
      "rms_revenue_days_to_reservation_graph",
      "rms_revenue_days_to_cancellation_graph",
      "segmentschannels_segments"
    ]
  }),
  computed: {
    ...mapState({
      revenue_range_kpis: state => state.rms.revenue_range_kpis,
      revenue_vs: state => state.rms.revenue_vs
    })
  }
};
</script>

<style lang="scss" scoped>
#rms-revenue-app {
  .kpis-col {
    margin-top: -6px !important;
  }
}
</style>
