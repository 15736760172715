<template>
  <div class="a-rms-vs-selector-single">
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <div class="grey--text">
          <v-btn
            depressed
            rounded
            v-bind="attrs"
            v-on="on"
            light
            :dark="dark"
            :outlined="dark"
            class="text-lowercase font-weight-regular"
            color="white"
            :large="large"
          >
            <b class="text-capitalize font-weight-bold">
              {{ $t(current_vs) }}
            </b>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
      <v-list dense>
        <v-list-item-group
          color="indigo"
          v-model="current_vs"
          @change="updateVs"
          mandatory
        >
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="item.code"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ getPeriodLiteral(item.code) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-rms-vs-selector-single",
  props: {
    value_vs: {
      type: String,
      default: "stly"
    },
    dark: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    hide_pickup: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    current_vs: "",
    available_items: [
      { code: "bud" },
      { code: "stly" },
      { code: "stlyn" },
      { code: "ly" },
      { code: "lyn" },
      { code: "pickup_1" },
      { code: "pickup_2" },
      { code: "pickup_3" },
      { code: "pickup_4" },
      { code: "pickup_5" },
      { code: "pickup_6" }
    ]
  }),
  methods: {
    getPeriodLiteral(period) {
      if (period.split("_")[0] === "pickup") {
        return "Pickup " + this.current_hotel.settings.pickup_spots[period];
      }
      return this.$t(period);
    },
    updateVs(vs) {
      this.$emit("updateVs", vs);
    }
  },
  computed: {
    items() {
      // Depedning on the value of hide-pickup, we will return the available items
      if (this.hide_pickup) {
        return this.available_items.filter(
          item => !item.code.includes("pickup")
        );
      }
      return this.available_items;
    },
    ...mapState({
      hotelsdot: state => state.hotelsdot
    }),
    ...mapGetters({
      current_hotel: "current_hotel"
    })
  },
  mounted() {
    this.current_vs = this.value_vs;
  }
};
</script>
