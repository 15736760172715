<template>
  <a-card no_title class="a-card-kpi-icon">
    <v-card-text>
      <v-row no-gutters :class="right ? 'flex-row-reverse' : ''">
        <v-col cols="8" class="d-flex flex-column info-wrapper">
          <slot name="title">
            <a-label class="mb-3">
              {{ $t(label) }}
            </a-label>
          </slot>
          <slot name="kpi">
            <a-kpi :kpi="kpi" :value="value" left large class="mb-0"></a-kpi>
          </slot>
          <slot name="increments">
            <div class="kpi-wrapper-2 large mb-3 mt-1">
              <a-increment
                v-for="(vs_value, index) in vs_values"
                :key="index"
                :value="value"
                :vs_value="vs_value"
                :period="periods[index + 1]"
                :kpi="kpi"
              >
              </a-increment>
            </div>
          </slot>
          <slot name="periods">
            <div class="periods-wrapper">
              <a-period-compare
                v-if="!hide_periods"
                :periods="periods"
              ></a-period-compare>
            </div>
          </slot>
        </v-col>
        <v-col cols="4" class="d-flex icon-wrapper">
          <slot name="icon">
            <v-avatar
              size="70"
              :color="icon_bg_color"
              :tile="!icon_circle"
              :class="!icon_circle ? 'rounded' : ''"
            >
              <v-icon :color="icon_color" size="32">
                {{ icon }}
              </v-icon>
            </v-avatar>
          </slot>
        </v-col>
      </v-row>
    </v-card-text>
  </a-card>
</template>

<script>
import aKpi from "@/components/rms_ui/aKpi";
import aIncrement from "@/components/rms_ui/aIncrement";
import aCard from "@/components/aCard";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare";
import aLabel from "@/components/rms_ui/aLabel";

export default {
  name: "a-card-kpi-icon",
  components: {
    aLabel,
    aPeriodCompare,
    aKpi,
    aIncrement,
    aCard
  },
  props: {
    kpi: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false
    },
    vs_values: {
      type: Array,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    periods: {
      type: Array,
      required: false
    },
    hide_periods: {
      type: Boolean,
      required: false
    },
    right: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    icon_color: {
      type: String,
      required: false,
      default: "secondary"
    },
    icon_bg_color: {
      type: String,
      required: false,
      default: "#ecf2ff"
    },
    icon_circle: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss">
.a-card-kpi-icon {
  .v-card__text.pt-1 {
    padding: 5px !important;
    height: 100%;
    .v-card__text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .info-wrapper {
    align-items: flex-start;
  }
  .icon-wrapper {
    justify-content: flex-end;
  }
  .periods-wrapper {
    .d-flex {
      width: fit-content;
    }
  }
  .flex-row-reverse {
    .info-wrapper {
      align-items: flex-end;
    }
    .icon-wrapper {
      justify-content: flex-start;
    }
  }
}
</style>
