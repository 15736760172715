<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "a-rms-revenue-servicetype-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    graph_data() {
      return {
        type: "bar",
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none",
        crosshairX: this.chart_settings.crosshair_settings,
        plotarea: {
          margin: "dynamic"
        },
        plot: {
          barWidth: "90%",
          borderRadius: 40
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineWidth: 0,
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.map(label => this.$t(label))
        },
        scaleY: {
          lineWidth: 0,
          format: "%v€",
          short: true,
          shortUnit: "K",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        }
      };
    },
    graph_series() {
      let series = [];
      for (let serie in this.series) {
        let sui = this.hotelsdot.series_ui[serie];
        series.push({
          text: serie,
          values: this.series[serie],
          backgroundColor: sui ? sui.color : "",
          lineColor: sui ? sui.color : "",
          marker: {
            backgroundColor: sui ? sui.color : ""
          }
        });
      }
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot
    })
  }
};
</script>
