<template>
  <div class="mt-n5 a-rms-revenue-channels">
    <v-tabs
      class="mb-4"
      background-color="transparent"
      v-model="tab"
      slider-size="1"
      light
      right
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>mdi-chart-line</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <a-rms-revenue-channels-table></a-rms-revenue-channels-table>
      </v-tab-item>
      <v-tab-item>
        <a-rms-revenue-channels-chart></a-rms-revenue-channels-chart>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import aRmsRevenueChannelsTable from "@/components/rms/aRmsRevenueChannelsTable.vue";
import aRmsRevenueChannelsChart from "@/components/chart/aRmsRevenueChannelsChart.vue";

export default {
  name: "a-rms-revenue-channels",
  components: {
    aRmsRevenueChannelsTable,
    aRmsRevenueChannelsChart
  },
  data: () => ({
    tab: 1
  })
};
</script>
<style lang="scss">
.a-rms-revenue-channels {
  .v-tab {
    min-width: 0;
  }
}
</style>
