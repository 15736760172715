<template>
  <div class="a-rms-revenue-bookings mt-n12">
    <v-tabs
      class="mb-4"
      background-color="transparent"
      v-model="tab"
      slider-size="1"
      light
      right
      small-caps
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        {{ $t("advance") }}
      </v-tab>
      <v-tab>
        {{ $t("cancellation") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <a-rms-revenue-days-to-reservation-chart
          :labels="reservation_graph.labels"
          :series="reservation_graph"
        ></a-rms-revenue-days-to-reservation-chart>
      </v-tab-item>
      <v-tab-item>
        <a-rms-revenue-days-to-cancellation-chart
          :labels="cancellation_graph.labels"
          :series="cancellation_graph"
        ></a-rms-revenue-days-to-cancellation-chart>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";
import aRmsRevenueDaysToReservationChart from "@/components/chart/aRmsRevenueDaysToReservationChart.vue";
import aRmsRevenueDaysToCancellationChart from "@/components/chart/aRmsRevenueDaysToCancellationChart.vue";

export default {
  name: "a-rms-revenue-bookings",
  components: {
    aRmsRevenueDaysToReservationChart,
    aRmsRevenueDaysToCancellationChart
  },
  data: () => ({
    tab: 0
  }),
  computed: {
    ...mapState({
      reservation_graph: state => state.rms.revenue_days_to_reservation_graph,
      cancellation_graph: state => state.rms.revenue_days_to_cancellation_graph
    })
  }
};
</script>
