import { render, staticRenderFns } from "./aPeriod.vue?vue&type=template&id=c03bd21e"
import script from "./aPeriod.vue?vue&type=script&lang=js"
export * from "./aPeriod.vue?vue&type=script&lang=js"
import style0 from "./aPeriod.vue?vue&type=style&index=0&id=c03bd21e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VTooltip})
