<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import * as Utils from "@/utils.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "a-rms-revenue-channels-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "400px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    }
  },
  computed: {
    graph_data() {
      return {
        type: "mixed",
        globals: this.chart_settings.globals,
        crosshairX: this.chart_settings.crosshair_settings,
        gui: this.chart_settings.gui,
        backgroundColor: "none",
        tooltip: {
          visible: false
        },
        plotarea: {
          margin: "dynamic"
        },
        plot: {
          barWidth: "90%",
          borderRadius: 40,
          bubbleWidth: "50%",
          minSize: 2,
          maxSize: 40
        },
        scaleX: {
          lineWidth: 0,
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          offsetStart: "12%",
          offsetEnd: "12%",
          labels: this.data.labels.map(item => {
            return Utils.getDimensionName(this.segments, item);
          })
        },
        scaleY: {
          lineWidth: 0,
          format: "%v€",
          short: true,
          shortUnit: "K",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        },
        scaleY2: {
          lineWidth: 0,
          format: "%v€",
          guide: {
            lineStyle: "solid",
            lineColor: "#d5d5d5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingLeft: "10px",
            color: "#333333"
          }
        }
      };
    },
    graph_series() {
      let series = [];
      if (this.data.series.room_revenue.otb) {
        series.push({
          type: "bar",
          scales: "scaleX,scaleY",
          text: "Room revenue OTB",
          backgroundColor: this.hotelsdot.series_ui["otb"].color,
          alpha: 0.9,
          values: this.data.series.room_revenue.otb
        });
      }
      if (this.data.series.room_revenue[this.revenue_vs]) {
        series.push({
          type: "bar",
          scales: "scaleX,scaleY",
          text: "Room revenue " + this.revenue_vs,
          backgroundColor: this.hotelsdot.series_ui[this.revenue_vs].color,
          alpha: 0.9,
          values: this.data.series.room_revenue[this.revenue_vs]
        });
      }
      if (this.data.series.rn_adr.otb) {
        series.push({
          type: "bubble",
          scales: "scaleX,scaleY2",
          text: "Rn y adr OTB",
          marker: {
            backgroundColor: "transparent",
            borderColor: this.hotelsdot.series_ui["otb"].color,
            borderWidth: 1,
            offsetX: -10
          },
          values: this.data.series.rn_adr.otb
        });
      }
      if (this.data.series.rn_adr[this.revenue_vs]) {
        series.push({
          type: "bubble",
          scales: "scaleX,scaleY2",
          text: "Rn y adr " + this.revenue_vs,
          marker: {
            backgroundColor: "transparent",
            borderColor: this.hotelsdot.series_ui[this.revenue_vs].color,
            borderWidth: 1,
            offsetX: 10
          },
          values: this.data.series.rn_adr[this.revenue_vs]
        });
      }
      /* let series = [
        {
          type: "bar",
          scales: "scaleX,scaleY",
          text: "Room revenue OTB",
          backgroundColor: this.hotelsdot.series_ui["otb"].color,
          alpha: 0.9,
          values: this.data.series.room_revenue.otb
        },
        {
          type: "bar",
          scales: "scaleX,scaleY",
          text: "Room revenue " + this.revenue_vs,
          backgroundColor: this.hotelsdot.series_ui[this.revenue_vs].color,
          alpha: 0.9,
          values: this.data.series.room_revenue[this.revenue_vs]
        },
        {
          type: "bubble",
          scales: "scaleX,scaleY2",
          text: "Rn y adr OTB",
          marker: {
            backgroundColor: "transparent",
            borderColor: this.hotelsdot.series_ui["otb"].color,
            borderWidth: 1,
            offsetX: -10
          },
          values: this.data.series.rn_adr.otb
        },
        {
          type: "bubble",
          scales: "scaleX,scaleY2",
          text: "Rn y adr " + this.revenue_vs,
          marker: {
            backgroundColor: "transparent",
            borderColor: this.hotelsdot.series_ui[this.revenue_vs].color,
            borderWidth: 1,
            offsetX: 10
          },
          values: this.data.series.rn_adr[this.revenue_vs]
        }
      ]; */
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      hotelsdot: state => state.hotelsdot,
      revenue_vs: state => state.rms.revenue_vs,
      segments: state => state.segmentschannels.segments
    }),
    ...mapGetters({
      data: "rms_revenue_channels_graph_data"
    })
  }
};
</script>
