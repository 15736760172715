<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "a-rms-revenue-reservation-percentage-chart",
  props: {
    height: {
      type: String,
      Number,
      default: "70px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Array,
      default: function() {
        return [];
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    crosshair_settings() {
      let crosshair_settings = JSON.parse(
        JSON.stringify(this.chart_settings.crosshair_settings)
      );
      crosshair_settings.visible = false;
      return crosshair_settings;
    },
    graph_data() {
      return {
        type: "hbar",
        globals: this.chart_settings.globals,
        crosshairX: this.crosshair_settings,
        gui: this.chart_settings.gui,
        backgroundColor: "none",
        plotarea: {
          margin: "30px 0 0 0"
        },
        plot: {
          stacked: true,
          borderRadius: 20,
          valueBox: {
            text: "%v%",
            placement: "bottom-in",
            fontColor: "white",
            fontSize: "18",
            offsetX: "5px"
          }
        },
        tooltip: {
          visible: false
        },
        legend: {
          visible: true,
          borderWidth: 0,
          padding: "0px",
          layout: "1x3",
          x: "0",
          y: "0",
          marker: {
            fontSize: "12px",
            type: "circle"
          }
        },
        scaleX: {
          visible: false
        },
        scaleY: {
          visible: false
        }
      };
    },
    graph_series() {
      let series = [
        {
          text: this.labels[0],
          values: [this.series[0]],
          backgroundColor: "#A9D604"
        },
        {
          text: this.labels[1],
          values: [this.series[1]],
          backgroundColor: "#CE4849"
        },
        {
          text: this.labels[2],
          values: [this.series[2]],
          backgroundColor: "#AC91FA"
        }
      ];
      return series;
    },
    ...mapState({
      chart_settings: state => state.chart_settings
    })
  }
};
</script>
