<template>
  <a-card :title="$t('reservation_percentage')">
    <a-rms-revenue-reservation-percentage-chart
      :labels="chart_data.labels"
      :series="chart_data.values"
    ></a-rms-revenue-reservation-percentage-chart>
  </a-card>
</template>

<script>
import { mapState } from "vuex";
import aCard from "@/components/aCard.vue";
import aRmsRevenueReservationPercentageChart from "@/components/chart/aRmsRevenueReservationPercentageChart.vue";

export default {
  name: "a-rms-revenue-reservation-percentage",
  components: {
    aCard,
    aRmsRevenueReservationPercentageChart
  },
  computed: {
    chart_data() {
      return {
        values: [this.ok, this.canceled, this.no_shows],
        labels: [this.$t("confirmed"), this.$t("cancelled"), this.$t("no_show")]
      };
    },
    total() {
      return (
        this.revenue_range_kpis.otb.rn +
        this.revenue_range_kpis.otb.canceled +
        this.revenue_range_kpis.otb.no_show
      );
    },
    ok() {
      return this.total > 0
        ? Math.round((this.revenue_range_kpis.otb.rn / this.total) * 100)
        : 0;
    },
    canceled() {
      return this.total > 0
        ? Math.round((this.revenue_range_kpis.otb.canceled / this.total) * 100)
        : 0;
    },
    no_shows() {
      return this.total > 0
        ? Math.round((this.revenue_range_kpis.otb.no_show / this.total) * 100)
        : 0;
    },
    ...mapState({
      revenue_range_kpis: state => state.rms.revenue_range_kpis,
      revenue_vs: state => state.rms.revenue_vs
    })
  }
};
</script>
