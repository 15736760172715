import { render, staticRenderFns } from "./aRmsRevenueKpis.vue?vue&type=template&id=93b8df98&scoped=true"
import script from "./aRmsRevenueKpis.vue?vue&type=script&lang=js"
export * from "./aRmsRevenueKpis.vue?vue&type=script&lang=js"
import style0 from "./aRmsRevenueKpis.vue?vue&type=style&index=0&id=93b8df98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b8df98",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
