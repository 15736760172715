<template>
  <div class="a-rms-revenue-servicetype">
    <v-row>
      <v-col cols="3" class="d-flex align-center justify-center py-0">
        <div>
          <div class="caption text-uppercase text-center mb-2">
            {{ $t("total_production") }}
          </div>
          <a-kpi
            kpi="room_revenue"
            :value="data.otb.revenue"
            :vs_values="[data[revenue_vs].revenue]"
            :vs_periods="[revenue_vs]"
            class="mb-5"
          ></a-kpi>
          <div class="caption text-uppercase text-center mb-2 mt-8">
            {{ $t("accommodation_production") }}
          </div>
          <a-kpi
            kpi="room_revenue"
            :value="data.otb.room_revenue"
            :vs_values="[data[revenue_vs].room_revenue]"
            :vs_periods="[revenue_vs]"
            class="mb-5"
          ></a-kpi>
          <div class="caption text-uppercase text-center mb-2 mt-8">
            {{ $t("f&b_production") }}
          </div>
          <a-kpi
            kpi="room_revenue"
            :value="data.otb.fb_revenue"
            :vs_values="[data[revenue_vs].fb_revenue]"
            :vs_periods="[revenue_vs]"
          ></a-kpi>
        </div>
      </v-col>
      <v-col cols="9" class="py-0">
        <a-rms-revenue-servicetype-chart
          :labels="graph_data.labels"
          :series="graph_data.series"
          height="420"
        ></a-rms-revenue-servicetype-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aRmsRevenueServicetypeChart from "@/components/chart/aRmsRevenueServicetypeChart.vue";

export default {
  name: "a-rms-revenue-servicetype",
  components: {
    aKpi,
    aRmsRevenueServicetypeChart
  },
  computed: {
    ...mapState({
      data: state => state.rms.revenue_summary_table,
      revenue_vs: state => state.rms.revenue_vs
    }),
    ...mapGetters({
      graph_data: "rms_revenue_servicetype_data"
    })
  }
};
</script>
