<template>
  <div
    class="subtitle-2  text-uppercase grey--text text--lighten-1"
    :class="classes"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "a-label",
  props: {
    clas: {
      type: String,
      default: ""
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return this.clas + " " + (this.inline ? "d-inline-block" : "d-block");
    }
  }
};
</script>
